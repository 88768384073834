<template>
  <div>
    <van-form ref="form">
      <!-- 基本信息 -->
      <van-cell-group title="基本信息">
        <van-field v-model="form.stuName" placeholder="请填写姓名" readonly label="姓名" />
        <van-field v-model="form.stuNo" placeholder="请填写学号" readonly label="学号" />
        <custom-select ref="schYear" v-model="form.schYear" :required="true" placeholder="请选择缴纳年度" label="年度" :columns="dict.xj_nj" />
        <custom-radio v-model="form.payStatus" label="缴纳状态" :required="true" :columns="statusDict" />
        <van-field v-model="form.amount" placeholder="请输入缴纳金额" :required="form.payStatus" :rules="[{ required: form.payStatus  } ]" label="缴纳金额" />
        <van-field v-model="form.remark" rows="3" class="textarea-style" :required="true" arrow-direction="" :rules="[{ required: true } ]" label="缴纳说明" type="textarea" maxlength="300" placeholder="请填写缴纳说明" show-word-limit />
      </van-cell-group>
      <!-- 其他信息 -->
      <van-cell-group title="证明附件" class="upload-file" style="margin-bottom:65px;">
        <file-upload v-if="refsh" accept=".doc,.docx,.pdf,.xls,.xlsx,.png,.gif,.jpeg" v-model="form.fileId" miz="STU_TUITION_PAY" biz="storage" :multiple="3" v-on:uploadResponse="uploadResponse" list-type="list" :auto-upload="true" :max-size="1">
          <span slot="tip" class="upload-tips">
            支持上传任意文件,文件最大1M
          </span>
        </file-upload>
      </van-cell-group>

      <div class="submit-button  shadow-style">
        <div>
          <van-button round block type="primary" :loading="loading" @click="doSubmit">提 交</van-button>
        </div>
      </div>
    </van-form>
  </div>
</template>

<script>

import { getTermYear } from '@/utils/index'
import { mapGetters } from 'vuex'
import fileUpload from "@/components/FileUpload";
import crudDailyTuitionPay from '@/api/modules/daily/dailyTuitionPay'


export default {
  name: "StuPayReport",
  components: { fileUpload },
  dicts: ['xj_nj', 'xj_xqgl'],
  data() {
    return {
      form: {
        id: null,
        stuNo: null,
        stuName: null,
        payStatus: true,
        remark: null,
        amount: null,
        files: null,
        fileId: [],
        schYear: null,
      },
      statusDict: [
        { label: "已缴纳", value: true },
        { label: "未缴纳", value: false },
      ],
      loading: false,
      refsh: true
    }
  },
  watch: {
    "dict.xj_xqgl": {
      deep: false,
      handler(newval, oldval) {
        if (newval.length > 0 && !this.$route.query.id) {
          this.schYearDefault();
        }
      },
    },
  },
  computed: {
    ...mapGetters(["user", "roles"]),
  },
  created() {
    this.$nextTick((e) => {
      if (this.$route.query.id) {
        crudDailyTuitionPay.findOneById(this.$route.query.id).then((res) => {
          this.form = { ...res }
          this.form.fileId = JSON.parse(res.files)
          // 强制刷新组件
          this.refsh = false
          this.$nextTick(() => {
            this.refsh = true
          })
        })
      } else {
        this.form.stuNo = this.user.username
        this.form.stuName = this.user.nickName
      }
    })
  },
  methods: {
    schYearDefault() {
      let termYear = getTermYear(
        this.dict.xj_xqgl,
        (new Date().getMonth() + 1).toString()
      )
      this.form.schYear = termYear
    },
    uploadResponse(fileIds, data) {
      if (fileIds.length) {
        this.form.fileId = fileIds;
      }
    },
    doSubmit() {
      this.$refs.form.validate().then(
        (res) => {
          if (this.form.fileId.length === 0) {
            this.$notify({
              type: "warning",
              message: "请上传附件证明！",
              duration: 1500,
            });
            return
          }
          // 验证通过数据提交
          this.$dialog
            .confirm({
              title: "提示",
              message: "你确定要进行提交操作吗？",
            })
            .then(() => {
              this.loading = true;
              if (this.$route.query && this.$route.query.id) {
                this.doEdit();
              } else {
                this.doAdd();
              }
            })
            .catch(() => {
              // on cancel
            });
        },
        (err) => {
          this.$notify({
            type: "warning",
            message: "有提交项未填写！",
            duration: 1500,
          });
        }
      );
    },
    cancel() {
      this.loading = false
      this.form = {
        id: null,
        stuNo: null,
        stuName: null,
        payStatus: true,
        remark: null,
        amount: null,
        files: null,
        fileId: [],
        schYear: null,
      }
      this.$router.go(-1)
    },
    doEdit() {
      crudDailyTuitionPay.edit(this.form).then((res) => {
        this.$notify({
          type: "success",
          message: "操作成功",
          duration: 2000,
        });
        this.cancel()
      })
    },
    doAdd() {
      crudDailyTuitionPay.add(this.form).then((res) => {
        this.$notify({
          type: "success",
          message: "操作成功",
          duration: 2000,
        });
        this.cancel()
      })
    },
  }
}
</script>

<style lang="scss" scoped>
::v-deep.radio-other-info {
  .van-cell {
    .van-field__label {
      width: 11.2em;
    }
  }
}

.van-field__label {
  width: 110px;
}
.van-cell {
  font-size: 15px;
  line-height: 32px;
  height: 50px;
}
.textarea-style {
  height: 100%;
}
</style>